<template>
    <Setup-Step
        @yes="f => addNew()"
        @no="f => f()"
        :loadingMsg="loadingMsg"
        :settings="settings"
        :stage.sync="stage"
        step="zones"
        title="Zones">
        <template v-slot:body>
            <v-tabs-items v-model="stage" touchless>
                <v-tab-item>
                    <BT-List-Endless
                        :canSearch="false"
                        :loadingMsg.sync="loadingMsg"
                        maxHeight="60vh"
                        navigation="zones"
                        :onPullSuccessAsync="pullZones"
                        :refreshToggle="refreshList">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.zoneName }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn small text @click="addNew(item)">change</v-btn>
                            </v-list-item-action>
                        </template>
                    </BT-List-Endless>
                    <p class="my-4">Would you like to add a zone?</p>
                </v-tab-item>

                <v-tab-item>
                    <BT-Field-String
                        label="Zone Name"
                        v-model="newItem.zoneName"
                        isEditing />

                    <BT-Btn 
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <v-tab-item v-if="newItem != null">
                    <p>{{ newItem.zoneName }}</p>
                        <GmapMap
                            ref="locMap"
                            :center="center"
                            :zoom="7"
                                style="width: 100%; height: 65vh;"
                                :options="{
                                    zoomControl: true,
                                    mapTypeControl: false,
                                    scaleControl: false,
                                    streetViewControl: false,
                                    rotateControl: false,
                                    fullscreenControl: false,
                                    disableDefaultUi: false }">
                                
                                <gmap-polygon
                                    v-for="(zone, index) in otherZones"
                                    :fillOpacity="0.5"
                                    :key="index + 'a'"
                                    :paths="zone.boundary"
                                    :strokeWeight="1" />

                                <gmap-polygon
                                    @paths_changed="($event) => { updatePointerPaths($event, newItem) }"
                                    clickable
                                    editable
                                    :fillOpacity="0.5"
                                    :paths="newItem.boundary"
                                    :strokeWeight="1" />
                        </GmapMap>

                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Done"
                            @click="save" />

                </v-tab-item>
            </v-tabs-items>
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Zone-Setup',
    components: {
        // SetupInfo: () => import('~home/setup/Setup-Info.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            center: { lat: -38, lng: 144 },
            loadingMsg: null,
            newItem: {},
            otherZones: [],
            refreshList: false,
            stage: 0,
            zones: []
        }
    },
    props: {
        settings: { //{ currentIndex: 0, stepperPlan: [], currentStep: 0 }
            type: Object,
            default: null
        },
    },
    methods: {
        addNew(j) {
            this.newItem = j || {
                id: null,
                rowVersion: null,
                boundary: this.getAreaAround(this.center, 1)
            };

            if (j != null && j.id != null) {
                this.otherZones = this.zones.filter(z => z.id != j.id);
            }
            else {
                this.otherZones = this.zones;
            }

            this.stage += 1;
        },
        pullZones(zones) {
            this.zones = zones;
            return zones;
        },
        async save() {
            try {
                this.loadingMsg = 'Saving';
                var res = null;

                if (this.newItem.id == null) {
                    res = await this.$BlitzIt.store.post('zones', this.newItem);
                }
                else {
                    res = await this.$BlitzIt.store.patch('zones', this.newItem);
                }
                
                this.newItem.id = res.id;
                this.newItem.rowVersion = res.rowVersion;

                this.stage = 0;
                this.refreshList = !this.refreshList;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        updatePointerPaths(mvcArray, zone) {
            let paths = [];
            for (let i=0; i<mvcArray.getLength(); i++) {
              let path = [];
              for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
                let point = mvcArray.getAt(i).getAt(j);
                path.push({lat: point.lat(), lng: point.lng()});
              }
              paths.push(path);
            }
            zone.boundary = paths[0];

            zone.changed = true;
        },
    }
}
</script>