var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Setup-Step',{attrs:{"loadingMsg":_vm.loadingMsg,"settings":_vm.settings,"stage":_vm.stage,"step":"zones","title":"Zones"},on:{"yes":function (f) { return _vm.addNew(); },"no":function (f) { return f(); },"update:stage":function($event){_vm.stage=$event}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.stage),callback:function ($$v) {_vm.stage=$$v},expression:"stage"}},[_c('v-tab-item',[_c('BT-List-Endless',{attrs:{"canSearch":false,"loadingMsg":_vm.loadingMsg,"maxHeight":"60vh","navigation":"zones","onPullSuccessAsync":_vm.pullZones,"refreshToggle":_vm.refreshList},on:{"update:loadingMsg":function($event){_vm.loadingMsg=$event},"update:loading-msg":function($event){_vm.loadingMsg=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.zoneName))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.addNew(item)}}},[_vm._v("change")])],1)]}}])}),_c('p',{staticClass:"my-4"},[_vm._v("Would you like to add a zone?")])],1),_c('v-tab-item',[_c('BT-Field-String',{attrs:{"label":"Zone Name","isEditing":""},model:{value:(_vm.newItem.zoneName),callback:function ($$v) {_vm.$set(_vm.newItem, "zoneName", $$v)},expression:"newItem.zoneName"}}),_c('BT-Btn',{attrs:{"buttonClass":"primary mx-1","inline":"","label":"Next"},on:{"click":function($event){_vm.stage += 1}}})],1),(_vm.newItem != null)?_c('v-tab-item',[_c('p',[_vm._v(_vm._s(_vm.newItem.zoneName))]),_c('GmapMap',{ref:"locMap",staticStyle:{"width":"100%","height":"65vh"},attrs:{"center":_vm.center,"zoom":7,"options":{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUi: false }}},[_vm._l((_vm.otherZones),function(zone,index){return _c('gmap-polygon',{key:index + 'a',attrs:{"fillOpacity":0.5,"paths":zone.boundary,"strokeWeight":1}})}),_c('gmap-polygon',{attrs:{"clickable":"","editable":"","fillOpacity":0.5,"paths":_vm.newItem.boundary,"strokeWeight":1},on:{"paths_changed":function ($event) { _vm.updatePointerPaths($event, _vm.newItem) }}})],2),_c('BT-Btn',{attrs:{"buttonClass":"primary mx-1 mt-4","inline":"","label":"Done"},on:{"click":_vm.save}})],1):_vm._e()],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }